app-toggle,
omedom-toggle {
    ion-item.omedom-toggle-item {
        --border-width: 0;
        --inner-border-width: 0;
        --show-full-highlight: 0;
        --color: $light-black;
        --placeholder-color: $light-black;
        --padding-end: 0;
        --padding-start: 0;
        --ion-safe-area-right: 0;

        > ion-toggle {
            -webkit-padding-end: 5px !important;
            padding-inline-end: 5px !important;
        }

        .omedom-toggle-item-label {
            --color: $light-black;
            font-size: 14px;
            text-overflow: clip;
            white-space: pre-wrap;
        }
    }
}
