.omedom-wide-swiper {
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 20px;
        left: 0;
        width: 100%;
        position: fixed !important;
    }

    .swiper-slide {
        padding: 20px;
    }
}
