@import './variable/color.scss';

ion-modal {
    &.success-modal {
        --backdrop-opacity: 0;
        --height: 70px;
        --box-shadow: 0 5px 10px #04151c1a;
        --border-radius: 35px;
        align-items: flex-start;
        padding: 40px 20px;

        ion-content {
            --background: transparent;
        }

        &::part(content) {
            background-color: $light-white;
            box-sizing: border-box;
            border: 1.5px solid $light-grey;
            border-radius: 35px;
            padding: 10px;
            top: 40px;
            left: 20px;
            right: 20px;
        }
    }
}
