ion-popover {
    &.level-trophy-popover {
        --backdrop-opacity: 0;

        ion-content {
            --background: transparent;
        }

        &::part(content) {
            background-color: $very-light-grey;
            width: 150px;
            border-radius: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;
        }
    }
}
