%omedom-stepper-separator {
    content: '';
    min-width: 16px;
    height: 2px;
    background-color: $light-grey;
    flex: 1;
}

app-stepper,
omedom-stepper {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;

    .omedom-stepper {
        &-header {
            display: flex;
            align-items: center;
            width: 100%;
            overflow: auto;
            justify-content: space-between;
            padding-bottom: 20px;
        }

        &-step {
            display: flex;
            align-items: center;

            &.selected {
                flex: 1;
            }

            &.selected,
            &.previous {
                .omedom-stepper-step-number {
                    color: $light-white;
                    background-color: $light-black;
                }
            }

            &:not(:last-child) {
                &.previous::after {
                    background-color: $light-black;
                }

                &::after {
                    @extend %omedom-stepper-separator;

                    margin: 0 0 0 10px;
                }
            }

            &:not(:first-child) {
                &.previous::before,
                &.selected::before {
                    background-color: $light-black;
                }

                &::before {
                    @extend %omedom-stepper-separator;
                    margin: 0 10px 0 0;
                }
            }

            &-number {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                color: $light-black;
                background-color: $light-grey;
            }

            &-label {
                white-space: nowrap;
            }
        }

        &-content {
            flex: 1;
        }
    }
}
