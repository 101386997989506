@font-face {
    font-family: 'omedom';
    src: url('../fonts/icon/omedom.ttf?rsxo07') format('truetype'),
        url('../fonts/icon/omedom.woff?rsxo07') format('woff'),
        url('../fonts/icon/omedom.svg?rsxo07#omedom') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='omedom-icon-'],
[class*=' omedom-icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'omedom' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 20px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.omedom-icon-crown:before {
    content: '\e900';
}
.omedom-icon-move:before {
    content: '\e901';
}
.omedom-icon-rent:before {
    content: '\e902';
}

.omedom-icon-bank:before {
    content: '\e903';
}
