app-tab,
omedom-tab {
    display: flex;
    margin: auto;
    overflow: auto;
    max-width: 100%;
    scrollbar-color: transparent;
    scrollbar-width: 2px;
    scrollbar-track-color: #eee;
    justify-content: space-evenly;
    align-items: center;

    &::-webkit-scrollbar {
        height: 5px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #eee;
        border-radius: 10px;
    }

    .omedom-tab {
        display: flex;
        align-items: center;
        padding: 10px;
        color: $light-white;
        cursor: pointer;
        user-select: none;

        &-label {
            margin-left: 12px;
        }

        &-icon {
            font-size: 20px !important;
        }

        &.selected {
            border: 1.5px solid;
            border-radius: $border-radius $border-radius 0 0;
            color: $light-black;

            &.omedom-tab-red {
                border-color: $light-orange;
                background: linear-gradient(
                        0deg,
                        rgba($light-orange, 0.3),
                        rgba($light-orange, 0.3)
                    ),
                    $light-white;
            }

            &.omedom-tab-green {
                border-color: $light-green;
                background: linear-gradient(
                        0deg,
                        rgba($light-green, 0.3),
                        rgba($light-green, 0.3)
                    ),
                    $light-white;
            }

            &.omedom-tab-yellow {
                border-color: $light-yellow;
                background: linear-gradient(
                        0deg,
                        rgba($light-yellow, 0.3),
                        rgba($light-yellow, 0.3)
                    ),
                    $light-white;
            }

            &.omedom-tab-blue {
                border-color: $light-blue;
                background: linear-gradient(
                        0deg,
                        rgba($light-blue, 0.3),
                        rgba($light-blue, 0.3)
                    ),
                    $light-white;
            }

            &.omedom-tab-white {
                border-color: $light-white;
                background: linear-gradient(
                        0deg,
                        rgba($light-white, 0.3),
                        rgba($light-white, 0.3)
                    ),
                    $light-white;
            }
        }
    }
}

@media (max-width: 600px) {
    app-tab {
        scrollbar-color: transparent;
        scrollbar-width: 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
