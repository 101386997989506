app-chart-legend,
omedom-chart-legend {
    display: block;
    color: $light-black;
    width: 100%;

    .omedom-chart-legend-item {
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 200px;
        margin: 10px 0;

        &:first-child {
            margin: 20px 0 10px;
        }

        &:last-child {
            margin: 10px 0 20px;
        }

        &-color {
            width: 20px;
            height: 10px;
            border-radius: $border-radius;
        }

        &-label {
            flex: 2;
            margin: 0 10px;
        }

        &-percent {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .omedom-chart-legend-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        gap: 10px;

        .omedom-chart-legend-empty-text {
            font-size: 14px;
            font-weight: 400;
        }
    }
}
