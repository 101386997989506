// Replicate changes in libs/data/src/lib/enum/colors.enum.ts OmedomColorsEnum

$very-light-grey: #f5f5f5;
$very-light-gray: #f5f5f5;
$very-light-blue: #b3d6ef;
$dark-grey: #606060;
$dark-gray: #606060;

$light-black: #04151c;
$light-white: #ffffff;
$light-grey: #eeeeee;
$light-gray: #eeeeee;
$light-blue: #0076c8;
$light-green: #00c29a;
$light-orange: #ff5641;
$light-yellow: #ffbe00;

$medium-grey: #a8adaf;
$medium-gray: #a8adaf;

$dark-blue: #3491d2;
$dark-green: #2dc2a4;
$dark-orange: #ff8273;
$dark-yellow: #ffd456;

$black: #000000;
$green: #01856e;
$red: #cd493a;
$yellow: #cc9900;
$grey: #737677;
$gray: #737677;

$pro-gold: #ffbe00;
$pro-brown: #864231;
$pro-grey: #bbb4a9;
$pro-gray: #bbb4a9;
$pro-yellow: #fde3a0;

.color {
    &-light-black {
        color: $light-black;
    }

    &-red {
        color: $red;
    }

    &-green {
        color: $green;
    }

    &-grey {
        color: $grey;
    }

    &-medium-grey {
        color: $medium-grey;
    }

    &-yellow {
        color: $yellow;
    }
}

.background-color {
    &-light {
        &-orange {
            background-color: $light-orange;
        }

        &-green {
            background-color: $light-green;
        }

        &-grey {
            background-color: $light-grey;
        }

        &-yellow {
            background-color: $light-yellow;
        }

        &-blue {
            background-color: $light-blue;
        }

        &-white {
            background-color: $light-white;
        }

        &-black {
            background-color: $light-black;
        }
    }

    &-very-light-grey {
        background-color: $very-light-grey;
    }
}
