@import './variable/color';
@import './variable/size';
@import './variable/spacing';
@import './variable/border';
@import './variable/shadow.scss';
@import './variable//text-style.scss';
@import 'action';
@import 'button';
@import 'card';
@import 'chart';
@import 'checkbox';
@import 'content';
@import 'date';
@import 'dialog';
@import 'divider';
@import 'header';
@import 'form';
@import 'icon';
@import 'info';
@import 'link';
@import 'modal';
@import 'pdf-modal';
@import 'popover';
@import 'radio';
@import 'select';
@import 'spinner';
@import 'stepper';
@import 'sub-tab';
@import 'success';
@import 'swiper';
@import 'tab';
@import 'toggle';
@import 'tutorial';
@import 'typo';

.omedom-no-margin {
    margin: 0 !important;
}

.omedom-invisible {
    visibility: hidden;
    height: 0;
}

.omedom-no-background {
    background: none !important;
}

.capitalize {
    text-transform: capitalize;
}

.flex {
    display: flex;
}

.bold {
    font-weight: bold;
}

.pointer {
    cursor: pointer;
}

.float {
    &-right {
        float: right;
    }
    &-left {
        float: left;
    }
}

.position-relative {
    position: relative;
}

.light-blur {
    filter: blur(3px);
}
.blur {
    filter: blur(4px);
}
.strong-blur {
    filter: blur(6px);
}

.icon-tutorial {
    font-size: 28px;
    color: $light-green;
}
