.omedom-form-label {
    font-weight: 500;
    color: $light-black;
    margin-bottom: 10px;
}

ion-item.omedom-form-item-app {
    --border-width: 0;
    --inner-border-width: 0;
    --show-full-highlight: 0;
    --color: $light-black;
    --placeholder-color: $medium-grey;
    --padding-end: 0;
    --padding-start: 0;
    --background: 'transparent';
    border-radius: $border-radius;
    border: 1.5px solid $light-grey;
    background-color: $very-light-grey;
    padding: 0 0 0 20px;

    &.autocomplete-selected,
    &.autocomplete-focused {
        border-radius: $border-radius $border-radius 0 0;
    }

    ion-label {
        --color: $medium-grey;

        &:disabled {
            --color: $light-black;
            cursor: not-allowed;
        }
    }

    a {
        cursor: pointer;
    }
}

app-input,
omedom-input {
    display: inline-block;
    width: 100%;
    position: relative;
}

omedom-autocomplete {
    display: block;
    width: 100%;
    position: relative;
}

ion-spinner.form-upload-spinner {
    width: 24px;
    height: 24px;
}

.omedom-form-error {
    color: $red;
    font-size: 12px;
    margin-top: 5px;
}

.omedom-pro {
    ion-item.omedom-form-item {
        background-color: $light-white;

        ion-label {
            --color: $medium-grey;

            &:disabled {
                --color: $light-black;
            }
        }
    }

    omedom-select-option {
        background-color: $light-white;
    }
}

.omedom-select {
    border: 0;
}

.omedom-file-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 600px;
    max-width: 100%;

    label {
        min-width: 200px;
    }

    .omedom-form-file-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.omedom-form-autocomplete {
    max-height: 200px;
    border-radius: 0 0 $border-radius $border-radius;
    overflow-y: auto;
    background: $light-white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 100;

    .omedom-autocomplete-item {
        padding: 10px 20px;
        cursor: pointer;

        &:hover {
            background-color: $light-grey;
        }
    }
}

.omedom-form-autocomplete-selected {
    background-color: $light-black;
    color: $light-white;
    padding: 10px 20px;
    border-radius: 0 0 $border-radius $border-radius;
}

.omedom-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}

.omedom-form-icon {
    font-size: 15px !important;
    color: $light-black;
    border-radius: 50%;
    border: 1.5px solid $light-white;
    padding: 5px;
    margin-left: -10px;
}
