app-info,
omedom-info {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: $very-light-grey;
    border-radius: $border-radius;

    > i {
        margin-right: 12px;
    }

    span {
        flex: 1;
    }

    button {
        margin-left: 10px;
    }
}
