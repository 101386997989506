app-select,
omedom-select {
    width: 100%;
    display: inline-flex;
    flex-direction: column;

    app-select-option,
    app-select-detailed-option,
    omedom-detailed-option,
    omedom-select-option {
        &:not(.is-selected) {
            .omedom-select-option {
                &-label {
                    color: $medium-grey;
                }
            }
        }

        &.is-selected {
            .omedom-select-option {
                &-label {
                    font-weight: bold;
                }
            }
        }
    }

    &-option {
        display: flex;
        align-items: center;
        padding: 0 20px;
        border-radius: $border-radius;
        border: 1.5px solid $light-grey;
        background-color: $very-light-grey;
        height: 48px;

        .omedom-select-option {
            &-label {
                flex: 1;
            }

            &-image,
            &-icon {
                margin-right: 10px;
                border-radius: 50%;
            }

            &-image {
                width: 24px;
                height: 24px;
            }
        }

        app-popover,
        omedom-popover {
            position: initial;
        }
    }

    &-bottom-sheet.ion-page {
        display: block;

        ion-content::part(scroll) {
            padding: 0 20px 20px;
            display: block;
            height: 50vh;
        }

        h3 {
            display: flex;
            align-items: center;
            background-color: $light-white;
            line-height: 58px;
            width: 100%;
            padding: 0 20px;
            margin: 0;
            position: sticky;
            top: 0;

            span {
                flex: 1;
            }
        }

        app-select-option,
        app-select-detailed-option,
        omedom-detailed-option,
        omedom-select-option {
            margin: 10px;
            background-color: $light-white;
            height: 60px;
            box-sizing: border-box;

            &.is-selected {
                border-width: 5px;
            }

            .omedom-select-option {
                &-label,
                &-amount {
                    font-weight: bold;
                    color: $light-black;
                }
            }
        }
    }

    &.disabled {
        app-select-detailed-option,
        omedom-detailed-option,
        app-select-option .omedom-select-option-label,
        omedom-select-option .omedom-select-option-label {
            color: $medium-grey;
        }
    }
}
