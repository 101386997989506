ion-content.omedom-page {
    --background: #04151c;

    &::part(scroll) {
        &::after,
        &::before {
            content: none;
        }
    }

    .omedom-page-container {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        max-width: 800px;
        margin: auto;

        .omedom-page-container-content {
            @extend .padding-2;
            @extend .flex-1;
            @extend .w-100;

            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $light-white;
            border-radius: $border-radius $border-radius 0 0;
            box-shadow: 0 -10px 15px 0 rgba($color: #04151c, $alpha: 0.04);

            &-wide {
                padding: 0;
                @extend .flex-1;
                @extend .w-100;

                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: $light-white;
                border-radius: $border-radius $border-radius 0 0;
                box-shadow: 0 -10px 15px 0 rgba($color: #04151c, $alpha: 0.04);
            }

            &.layout-mobile {
                box-shadow: none;
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    ion-content.omedom-page {
        --background: linear-gradient(
            to bottom,
            #04151c 0%,
            #04151c 50%,
            white 50%,
            white 100%
        );
    }
}
