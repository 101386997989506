app-sub-tab,
omedom-sub-tab {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: $very-light-grey;
    border-radius: $border-radius;
    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;
    gap: 10px;

    .omedom-sub-tab {
        flex: 1;
        text-align: center;
        border-radius: $border-radius;
        color: $medium-grey;
        font-weight: 500;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        max-width: 50%;

        &.selected {
            max-width: 50%;
            background-color: $light-black;
            color: $light-white;
        }
        transition:
            background-color 0.28s,
            color 0.28s;
        &:not(.disabled) {
            cursor: pointer;
        }
        &-label {
            display: block;
            margin: 14.5px 0;
            padding: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: wrap;
        }

        &-icon {
            margin: 10px;
        }
        app-popover,
        omedom-popover {
            margin: 0 5px;
            color: black;
            position: inherit;
        }

        &-desktop {
            display: flex;
            flex-direction: column-reverse !important;
            gap: 5px !important;
            padding: 5px;
            > .omedom-sub-tab-label {
                margin: 0 !important;
                &p {
                    margin: 0 !important;
                }
            }

            > .omedom-sub-tab-icon {
                margin: 0 !important;
            }
        }
    }
}
