app-date,
omedom-date {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: white;
    border: 1.5px solid $light-grey;
    height: 50px;
    padding: 10px;
    border-radius: $border-radius;

    .omedom-date {
        &-info {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            &-icon {
                padding-right: 12px;
                font-size: 20px !important;
            }

            &-label {
                margin-bottom: 10px;
            }
        }
    }
    ion-model {
        width: 314px;
        height: 194px;
        --background: rgba(44, 39, 45, 0.2);
        &::part(content) {
            backdrop-filter: blur(6px);
        }
        ion-content {
            --background: transparent;
            --padding-top: 25px;
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-top: 25px;
        }
    }
}

ion-datetime {
    --background: transparent;
}
