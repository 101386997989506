// :host {
.omedom-checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
        cursor: pointer;
    }
}
.omedom-checkbox {
    --background-checked: #ffffff;
    --border-color-checked: #04151c;
    --checkmark-color: #04151c;

    .checkbox-checked .checkbox-icon,
    .checkbox-indeterminate .checkbox-icon {
        border-color: var(--border-color-checked);
        background: var(--background-checked);
    }
}
// }
