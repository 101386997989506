.margin {
    &-0 {
        margin: 0;
    }

    &-1 {
        margin: 10px;
    }

    &-2 {
        margin: 20px;
    }

    &-3 {
        margin: 30px;
    }

    &-4 {
        margin: 40px;
    }

    &-x {
        &-1 {
            margin-left: 10px;
            margin-right: 10px;
        }

        &-2 {
            margin-left: 20px;
            margin-right: 20px;
        }

        &-3 {
            margin-left: 30px;
            margin-right: 30px;
        }

        &-4 {
            margin-left: 40px;
            margin-right: 40px;
        }
        &-auto {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &-y {
        &-1 {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        &-2 {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &-3 {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &-4 {
            margin-top: 40px;
            margin-bottom: 40px;
        }
        &-auto {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &-top {
        &-1 {
            margin-top: 10px;
        }

        &-2 {
            margin-top: 20px;
        }

        &-3 {
            margin-top: 30px;
        }

        &-4 {
            margin-top: 40px;
        }
        &-auto {
            margin-top: auto;
        }
    }

    &-right {
        &-1 {
            margin-right: 10px;
        }

        &-2 {
            margin-right: 20px;
        }

        &-3 {
            margin-right: 30px;
        }

        &-4 {
            margin-right: 40px;
        }
        &-auto {
            margin-right: auto;
        }
    }

    &-bottom {
        &-1 {
            margin-bottom: 10px;
        }

        &-2 {
            margin-bottom: 20px;
        }

        &-3 {
            margin-bottom: 30px;
        }

        &-4 {
            margin-bottom: 40px;
        }
        &-auto {
            margin-bottom: auto;
        }
    }

    &-left {
        &-1 {
            margin-left: 10px;
        }

        &-2 {
            margin-left: 20px;
        }

        &-3 {
            margin-left: 30px;
        }

        &-4 {
            margin-left: 40px;
        }
        &-auto {
            margin-left: auto;
        }
    }
}

.padding {
    &-0 {
        padding: 0;
    }

    &-1 {
        padding: 10px;
    }

    &-2 {
        padding: 20px;
    }

    &-3 {
        padding: 30px;
    }

    &-4 {
        padding: 40px;
    }

    &-x {
        &-1 {
            padding-left: 10px;
            padding-right: 10px;
        }

        &-2 {
            padding-left: 20px;
            padding-right: 20px;
        }

        &-3 {
            padding-left: 30px;
            padding-right: 30px;
        }

        &-4 {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    &-y {
        &-1 {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &-2 {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        &-3 {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        &-4 {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    &-top {
        &-1 {
            padding-top: 10px;
        }

        &-2 {
            padding-top: 20px;
        }

        &-3 {
            padding-top: 30px;
        }

        &-4 {
            padding-top: 40px;
        }
    }

    &-right {
        &-1 {
            padding-right: 10px;
        }

        &-2 {
            padding-right: 20px;
        }

        &-3 {
            padding-right: 30px;
        }

        &-4 {
            padding-right: 40px;
        }
    }

    &-bottom {
        &-1 {
            padding-bottom: 10px;
        }

        &-2 {
            padding-bottom: 20px;
        }

        &-3 {
            padding-bottom: 30px;
        }

        &-4 {
            padding-bottom: 40px;
        }
    }

    &-left {
        &-1 {
            padding-left: 10px;
        }

        &-2 {
            padding-left: 20px;
        }

        &-3 {
            padding-left: 30px;
        }

        &-4 {
            padding-left: 40px;
        }
    }
}

.omedom-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.omedom-sticky-container {
    position: sticky;
    bottom: 10px;
    top: 100%;
}

.omedom-center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.omedom-vertical-flex {
    display: flex;
    flex-direction: column;
}
