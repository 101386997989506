app-sub-tab,
omedom-sub-tab {
    display: flex;
    align-items: flex-end;
    // Border radius only for the top
    border-radius: $border-radius $border-radius 0 0;

    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;

    .omedom-sub-tab {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid $medium-grey;
        max-width: 50%;

        &.selected {
            max-width: 50%;

            > .omedom-sub-tab-content {
                background-color: $light-black;
                color: $light-white;
            }

            &-right {
                border-image: linear-gradient(
                        to left,
                        $light-black 0,
                        $light-black calc(100% - 6px),
                        $medium-grey calc(100% - 6px)
                    )
                    1;
            }

            &-left {
                border-image: linear-gradient(
                        to right,
                        $light-black 0,
                        $light-black calc(100% - 6px),
                        $medium-grey calc(100% - 6px)
                    )
                    1;
            }

            &-center {
                border-image: linear-gradient(
                        to right,
                        $medium-grey 0,
                        $medium-grey 6px,
                        $light-black 6px,
                        $light-black calc(100% - 6px),
                        $medium-grey calc(100% - 6px)
                    )
                    1;
            }
            border-bottom: 2px solid $light-black;
        }

        &:not(.selected):hover {
            > .omedom-sub-tab-content {
                background-color: $light-grey;
            }
        }
    }

    .omedom-sub-tab-content {
        flex: 1;
        text-align: center;
        border-radius: $border-radius $border-radius 0 0;
        color: $light-black;

        font-weight: 500;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        transition:
            background-color 0.28s,
            color 0.28s,
            border-bottom 0.28s,
            border-image 0.28s;
        &:not(.disabled) {
            cursor: pointer;
        }

        &-label {
            display: block;
            margin: 14.5px 0;
            padding: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: wrap;
        }

        &-icon {
            margin: 12.5px 0;
            padding: 5px;
        }
        app-popover,
        omedom-popover {
            margin: 0 5px;
            color: black;
            position: inherit;
        }

        &-desktop {
            display: flex;
            flex-direction: column-reverse !important;
            gap: 5px !important;
            padding: 5px;
            > .omedom-sub-tab-label {
                margin: 0 !important;
                &p {
                    margin: 0 !important;
                }
            }

            > .omedom-sub-tab-icon {
                margin: 0 !important;
            }
        }
    }
}
