// Bouton pour la partie tuto
button.shepherd-button {
    background-color: $light-black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    padding: 10px;
    font-size: 16px;
    transition: none;
    color: $light-white;

    &:not(:disabled):hover {
        background-color: $light-black;
        color: $light-white;
    }
}

// Bouton classique
%omedom-button {
    padding: 0 16px;
    border-radius: $border-radius;
    text-align: center;
    border: 1.5px solid transparent;
    font-size: 16px;
    transition: all 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 50px;
    cursor: pointer;

    &:disabled {
        background-color: $light-grey;
        color: $medium-grey;
    }
    i {
        font-size: 20px !important;
    }
}

.omedom-button {
    &-primary {
        @extend %omedom-button;

        background-color: $light-black;
        color: $light-white;

        &:disabled {
            background-color: $light-grey;
            color: $medium-grey;
        }
    }

    &-secondary {
        @extend %omedom-button;

        background-color: $light-white;
        color: $light-black;
        border: 1.5px solid $light-black;

        &:disabled {
            background-color: $light-grey;
            color: $medium-grey;
            border-color: $medium-grey;
        }
    }

    &-warn {
        @extend %omedom-button;

        background-color: $light-orange;
        color: $light-black;

        &:disabled {
            background-color: $light-grey;
            color: $medium-grey;
        }
    }

    &-warn-secondary {
        @extend %omedom-button;

        background-color: $light-white;
        color: $red;
        border: 1.5px solid $light-orange;

        &:disabled {
            background-color: $light-grey;
            color: $medium-grey;
            border-color: $medium-grey;
        }
    }

    &-green {
        @extend %omedom-button;

        background-color: $light-green;
        color: $light-black;

        &:disabled {
            background-color: $light-grey;
            color: $medium-grey;
        }
    }

    &-yellow {
        @extend %omedom-button;

        background-color: $light-yellow;
        color: $light-black;

        &:disabled {
            background-color: $light-grey;
            color: $medium-grey;
        }
    }

    &-outline {
        @extend %omedom-button;

        background-color: $light-black;
        color: $light-white;
        border: 1.5px solid $light-white;

        &:disabled {
            background-color: $light-grey;
            color: $medium-grey;
            border: none;
        }
    }

    &-pro {
        @extend %omedom-button;

        background-color: $pro-grey;
        color: $light-black;
        border-radius: $border-radius-large;
        flex-direction: column;
        padding: 10px;
        height: auto;
        font-size: 10px;
    }

    &-desktop {
        border-radius: 50px !important;
        max-width: 460px !important;
    }

    &-gradient {
        @extend %omedom-button;

        background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0.5)
            ),
            linear-gradient(118.33deg, #ffd24d 0.04%, #0076c8 99.97%);
        border-radius: $border-radius-large;
        padding: 20px;
    }
}

// Button qui contient juste une icône
%omedom-icon-button {
    width: 50px;
    height: 50px;
    font-size: 20px !important;
    border-radius: $border-radius;

    &:not(:last-child) {
        margin-right: 20px;
    }

    &[disabled] {
        background-color: $light-grey;
        color: $medium-grey;
        border-color: $medium-grey;
    }
}

.omedom-icon-button {
    &-primary {
        @extend %omedom-icon-button;

        background-color: $light-black;
        color: $light-white;
    }

    &-secondary {
        @extend %omedom-icon-button;

        background-color: $light-white;
        color: $light-black;

        &-outline {
            @extend %omedom-icon-button;

            background-color: $light-white;
            color: $light-black;
            border: 1.5px solid $light-black;
        }
    }

    &-warn {
        @extend %omedom-icon-button;

        background-color: $light-orange;
        color: $light-black;
    }

    &-warn-secondary {
        @extend %omedom-icon-button;

        background-color: $light-white;
        color: $red;
        border: 1.5px solid $light-orange;

        &:disabled {
            background-color: $light-grey;
            color: $medium-grey;
            border-color: $medium-grey;
        }
    }

    &-desktop {
        border-radius: 50% !important;
        height: 40px !important;
        width: 40px !important;
        border-color: $medium-grey;
    }
}

%omedom-medium-icon-button {
    width: 40px;
    height: 40px;
    font-size: 20px !important;
    border-radius: $border-radius;
    padding: auto;

    &:not(:last-child) {
        margin-right: 10px;
    }

    &[disabled] {
        background-color: $light-grey;
        color: $medium-grey;
        border-color: $medium-grey;
    }
    img {
        margin-bottom: -2px;
    }
}

.omedom-medium-icon-button {
    &-primary {
        @extend %omedom-medium-icon-button;

        background-color: $light-black;
        color: $light-white;
    }

    &-secondary {
        @extend %omedom-medium-icon-button;

        background-color: $light-white;
        color: $light-black;

        &-outline {
            @extend %omedom-medium-icon-button;

            background-color: $light-white;
            color: $light-black;
            border: 1.5px solid $light-black;
        }
    }
    &-warn {
        @extend %omedom-medium-icon-button;

        background-color: $light-orange;
        color: $light-black;
    }

    &-warn-secondary {
        @extend %omedom-medium-icon-button;

        background-color: $light-white;
        color: $red;
        border: 1.5px solid $light-orange;

        &:disabled {
            background-color: $light-grey;
            color: $medium-grey;
            border-color: $medium-grey;
        }
    }
    &-green {
        @extend %omedom-medium-icon-button;

        background-color: $light-green;
        color: $light-black;
    }
}

// Bouton container
.omedom-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

// Bouton toggle
.omedom-button-toggle {
    display: flex;
    flex-direction: column;
    height: 90px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    background-color: $light-white;
    border: 1.5px solid $light-grey;
    color: $light-black;
    border-radius: $border-radius;
    width: calc(50% - 15px);
    margin-bottom: 30px;
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    cursor: pointer;

    &:nth-child(odd) {
        margin-right: 30px;
    }

    &.selected {
        background-color: $light-grey;
    }

    &-icon {
        font-size: 20px !important;
    }

    &-title {
        font-size: 16px;
        margin-top: 10px;
    }
}

// Container sticky qui contient les boutons
.omedom-sticky-button-container {
    position: sticky;
    bottom: 20px;
    z-index: 2;
    display: flex;
    flex-flow: row wrap;
    margin-top: auto;
    padding-top: 20px;
}
