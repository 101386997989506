.omedom-modal-fullscreen {
    --width: 90%;
    --height: 90%;

    &::part(scroll) {
        &::after,
        &::before {
            content: none;
        }
    }
}

.omedom-modal-padding {
    > .ion-page {
        height: calc(100vh - 40px);
        overflow: auto;
        padding: 20px;
    }
}
