app-radio,
omedom-radio {
    color: $light-black;
    position: relative;
    display: inline-block;
    width: 100%;

    .omedom-radio {
        &-label {
            font-weight: 500;
        }

        &-option-container {
            &-row {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
            }

            &-column {
                display: flex;
                margin-top: 12px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }

            > * {
                flex: 1;
            }
        }

        &-option {
            display: flex;
            align-items: center;
            margin-right: 20px;

            input[type='radio'] {
                width: 20px;
                height: 20px;
                margin-right: 12px;
            }
        }
    }
}
