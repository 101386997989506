.omedom-content-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    position: relative;
    padding-bottom: 0 !important;
    &.web {
        height: 100vh;
        & .previsualisation {
            flex: 1;
            border: 1.5px solid #eeeeee;
            height: calc(100vh - 115px);
        }
    }
    &.android {
        max-height: 100vh;
        & .previsualisation {
            background-color: $light-grey;
            min-height: 20vh;
        }
    }
}
