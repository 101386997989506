/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import '../../../libs/ui/src/lib/assets/style/style.scss';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* Swiper CSS for style sliders */
@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';
@import 'swiper/scss/pagination';

@font-face {
    font-family: 'Asap';
    src: url('../../../libs/ui/src/lib/assets/fonts/Asap-VariableFont_wght.ttf');
}

body {
    background: $very-light-grey;
    max-height: calc(
        100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    );
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);
}

* {
    font-family: Asap, sans-serif !important;
}

.swiper .swiper-pagination-bullet-active {
    width: 20px;
    border-radius: 20px;
    transition: all 0.4s ease-in-out;
}

ion-toast {
    --border-radius: 20px;
    font-size: 16px;
}

ion-modal {
    --border-radius: 20px;
    --height: auto;
    --max-height: 100svh;
    --overflow: auto;
}

i {
    font-size: 24px;
}

.clickable {
    cursor: pointer;
}

.no-pointer {
    cursor: initial !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    border: none;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $medium-grey;
    border-radius: $border-radius;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $light-black;
}
