@mixin line-clamp($lines, $line-height) {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    max-height: $lines * $line-height;
    text-overflow: ellipsis;
    overflow: hidden;
}

app-header,
omedom-header {
    display: block;
    color: $light-white;
    background-color: $light-black;
    padding: 0 20px;

    .header-title {
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px 0px;

        &-previous i {
            font-size: 20px !important;
        }

        &-label {
            display: flex;
            align-items: center;
            overflow-x: hidden;
            overflow-y: visible;

            &-img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1.5px solid $light-white;
                object-fit: cover;
            }

            &-value {
                @include line-clamp(3, 30px);
            }
        }
    }
    .info-container {
        position: relative;
        width: 100%;
        align-items: center;
        display: flex;

        app-popover,
        omedom-popover {
            top: 20%;
        }
    }

    .header-icon {
        font-size: 20px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: $light-black;
    }
}
