app-action,
omedom-action {
    display: flex !important;
    flex-direction: row !important;
    padding: 20px;
    gap: 10px;
    justify-content: space-between !important;
    align-items: center;
    border-radius: $border-radius;
    background-color: $very-light-grey;
    border: 1.5px solid $light-grey;

    button {
        min-width: 50px;
        min-height: 50px;
    }

    .smart-option-icon {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        top: 4px;
        color: #04151c;
    }
}
