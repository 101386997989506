.shepherd-element.shepherd-enabled {
    transition: none;
    border-radius: $border-radius;

    .shepherd-content {
        background-color: $very-light-grey;
        border-radius: $border-radius;
    }
    .smart-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        margin: 10px 0px 0px 0px;

        font-style: italic;
        font-size: 14px;
        line-height: 16px;
        justify-content: space-between;
    }
    .italicText {
        padding-top: 20px;
        font-style: italic;
        font-size: 14px;
        line-height: 16px;
    }
    .shepherd-text {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .tutorial-text {
            margin: 0 12px;
            color: $black;
        }

        .uil {
            text-decoration-color: $light-black;
        }
    }

    footer {
        padding: 20px;

        button.shepherd-button {
            flex: 1;
        }
        .cancelButton {
            background: #f5f5f5;
            color: #04151c;
            border: 1.5px solid #04151c;
            border-radius: 10px;
        }
    }
}

svg.shepherd-modal-overlay-container.shepherd-modal-is-visible {
    opacity: 0.3;
    fill: $light-black;
}
