h1 {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    margin: 0;
}

h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    margin: 0;
}

h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    margin: 0;
}

h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
}

h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
}
