.omedom-modal {
    padding: 20px;

    &-header {
        display: flex;
        position: relative;

        &-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;

            > i {
                border-radius: 50%;
                height: 64px;
                width: 64px;
                font-size: 20px !important;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            h3 {
                margin: 20px 0;
            }
        }

        > i {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &-content {
        margin: 20px 0 40px;

        > p {
            font-weight: 500;
            margin: 20px 0;
        }
    }

    &-buttons {
        display: flex;

        > * {
            flex: 1;
        }

        > :first-child {
            margin-right: 20px;
        }
    }

    &-buttons-wide {
        display: flex;
        flex-direction: column;

        > :first-child {
            margin-bottom: 10px;
        }
    }
}
.smart-option-container {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
