.w {
    &-10 {
        width: 10%;
    }

    &-20 {
        width: 20%;
    }

    &-25 {
        width: 25%;
    }

    &-30 {
        width: 30%;
    }

    &-33 {
        width: 33%;
    }

    &-50 {
        width: 50%;
    }

    &-60 {
        width: 60%;
    }

    &-66 {
        width: 66%;
    }

    &-70 {
        width: 70%;
    }

    &-75 {
        width: 75%;
    }

    &-80 {
        width: 80%;
    }

    &-90 {
        width: 90%;
    }

    &-100 {
        width: 100%;
    }
}

.h {
    &-50 {
        height: 50%;
    }

    &-100 {
        height: 100%;
    }
}

.flex {
    &-1 {
        flex: 1;
    }

    &-end {
        align-items: flex-end;
    }
}

.inline {
    &-block {
        display: inline-block;
    }
}

.top {
    &-0 {
        top: 0;
    }

    &-100 {
        top: 100%;
    }
}
